import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";

const PartyOver = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page not found | Puzzicle</title>
      </Helmet>
      <div className="Error">
        <div className="content">
          <h3>Party Over</h3>
          <h4>Oops! Party not found</h4>
          <p>
            The party you are looking was, has been over and removed, you many
            start the new party or join our(Platform) hosted parties.
          </p>
          <Link to="/" className="ripple">
            Back To Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default PartyOver;

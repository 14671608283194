import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Slider } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeDown,
  VolumeMute,
} from "@mui/icons-material";

import "./index.scss";

const VolumeSlider = withStyles({
  root: {
    height: 40,
    color: "#00BAC7",
  },
  thumb: {
    height: 10,
    width: 10,
    backgroundColor: "#00BAC7",
    border: "2px solid #00BAC7",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 40,
    borderRadius: 4,
  },
  rail: {
    height: 40,
    borderRadius: 4,
  },
})(Slider);
const PrettoSlider = withStyles({
  root: {
    height: 8,
    color: "#00BAC7",
    padding: "15px 0 !important",
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#00BAC7",
    border: "2px solid #00BAC7",
    marginTop: -1,
    // marginLeft: -1,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const VideoController = forwardRef(
  (
    {
      vidMode,
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      // onDuration,
      onPlayPause,
      playing,
      played,
      elapsedTime,
      totalDuration,
      muted,
      onVolumeSeekDown,
      volume,
      onVolumeChange,
    },
    ref
  ) => {
    const [showVolumeBar, setshowVolumeBar] = useState(false);
    const showVolumeBarHandle = () => {
      setshowVolumeBar(!showVolumeBar);
    };
    return (
      <div ref={ref} className="controls-wrapper">
        <div className="duration-wrapper">
          <span>{elapsedTime}</span>
          <span>{totalDuration}</span>
        </div>
        <div className="pause-wrapper">
          <IconButton onClick={onPlayPause}>
            {!vidMode && <>{playing ? <Pause /> : <PlayArrow />}</>}
          </IconButton>
        </div>
        <div className="progress-wrapper">
          <PrettoSlider
            min={0}
            max={100}
            aria-label="custom thumb label"
            className="video-progress"
            value={played * 100}
            onChange={onSeek}
            onMouseDown={onSeekMouseDown}
            onChangeCommitted={onSeekMouseUp}
            // onDuration={onDuration}
          />
        </div>

        <div className="volume-wrapper">
          <IconButton
            onClick={() => {
              showVolumeBarHandle();
            }}
          >
            {muted ? (
              <VolumeMute />
            ) : volume > 0.5 ? (
              <VolumeUp />
            ) : (
              <VolumeDown />
            )}
          </IconButton>
          {showVolumeBar && (
            <div className="volume-bar">
              <VolumeSlider
                min={0}
                max={100}
                value={muted ? 0 : volume * 100}
                onChange={onVolumeChange}
                aria-labelledby="input-slider"
                orientation="vertical"
                //   className={classes.volumeSlider}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onVolumeSeekDown}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
VideoController.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
};
export default VideoController;

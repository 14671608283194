import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import CourseVideoPlayer from "./partials/videoPlayer";
import Loader from "../../../loader/index";
import "./index.scss";

const VideoModal = ({ toggle, video, channel, title, buildQuestions }) => {
  const partylink = `${window.location.href}`;
  const [questions, setquestions] = useState(null);
  const config = {
    header: {
      "Content-Type": "application/json",
    },
  };
  const handleClose = () => toggle();
  const copyLink = () => {
    navigator.clipboard.writeText(partylink);
    toast.success("link copied to clipboard");
  };
  useEffect(() => {
    setquestions([]);

    if (buildQuestions) {
      let tempQuestions = [];
      buildQuestions.forEach((element) => {
        if (element.details.type == "mcq") {
          let options = [];
          element.details.options.forEach((opt) =>
            options.push({ content: opt })
          );
          tempQuestions.push({
            type: "multiple-choice",
            time_marker: parseInt(element.time) == 0 ? 1 : element.time,
            question: element.details.text,
            answers: [parseInt(element.details.correct_answer)],
            choice: options,
            hasImage: false,
            ID: element.ID,
          });
        } else {
          tempQuestions.push({
            type: "open-ended",
            time_marker: parseInt(element.time) == 0 ? 1 : element.time,
            question: element.details.text,
            answers: [parseInt(element.details.correct_answer)],
            ID: element.id,
          });
        }
      });
      setquestions(tempQuestions);
    }
  }, []);
  const QuestionType = (type) => {
    let quesType = "";
    if (type === "mcq" || type === "multiple-choice") {
      quesType = "multiple-choice";
    } else if (type === "subjective" || type === "open-ended") {
      quesType = "open-ended";
    } else if (type === "audio") {
      quesType = "audio-note";
    } else if (type === "video") {
      quesType = "video-note";
    } else if (type === "note") {
      quesType = "note";
    } else if (type === "note") {
      quesType = "note";
    } else if (type === "poll") {
      quesType = "poll";
    }
    return quesType;
  };
  useEffect(() => {
    if (!buildQuestions) {
      axios
        .get(
          `https://webhooks.mongodb-realm.com/api/client/v2.0/app/puzzicle-zgrpv/service/getAllVideos/incoming_webhook/webhook0?videoId=${video}&cn=${channel}`,
          config
        )
        .then((res) => {
          let foundElement = res.data?.annotations?.questions.find(function (
            item
          ) {
            return item.is_manual || item.is_ai;
          });
          let quesId = null;
          let ques = [];

          if (foundElement) {
            quesId = foundElement.ID;
            let filterQes = res.data?.annotations?.questions.filter(
              (ele) => ele.ID === quesId && ele.type !== "open-ended"
            );

            let questions = [];
            filterQes.map((item) => {
              let obj = {
                videoId: video,
                ID: item.ID,
                answers: item.answers,
                choice: item.choice,
                hasImage: item.hasImage ? item.attachment_link : item.hasImage,
                question: item.question,
                isPlayed: false,
                type: QuestionType(item.type),
                display_duration: item.duration,
                time_marker: item.time_marker,
                attachment:
                  !item.hasImage && item.attachment_link
                    ? item.attachment_link
                    : null,
                is_discussion: item.is_discussion ? item.is_discussion : false,
              };
              questions.push(obj);
            });
            ques = questions;
          } else {
            quesId = res.data?.annotations?.questions[0].ID;
            let questions = res.data?.annotations?.questions.filter(
              (ele) => ele.ID === quesId && ele.type !== "open-ended"
            );
            ques = questions;
          }

          setquestions(
            [...ques].sort((a, b) => {
              return a.time_marker - b.time_marker;
            })
          );
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          setquestions([]);
        });
    }
  }, []);

  return (
    <section className="course-video-wrapper">
      <div className="video-header">
        <Button className="close-wrapper" onClick={handleClose}>
          <Close />
        </Button>
        <h4 className="title">{title}</h4>
      </div>
      <div className="video-player-wrapper">
        {questions ? (
          <CourseVideoPlayer
            questions={questions}
            video={video}
            linkHandler={copyLink}
            partylink={partylink}
          />
        ) : (
          <div style={{ height: "500px", position: "relative" }}>
            <Loader />
          </div>
        )}
      </div>
    </section>
  );
};

export default VideoModal;

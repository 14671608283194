import React, { useEffect } from "react";
import axios from "axios";
import "./index.scss";

const DiscardLoginRedirect = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_ENV}/api/discord?code=${code}`)
      .then((res) => {
        localStorage.setItem("loggedInUser", JSON.stringify(res.data.user));
        localStorage.setItem("token", res.data.token);
        window.location.replace("/");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="discord">
      <p>Fetching your information from discord </p>
      <p>Please wait . . .</p>
    </div>
  );
};

export default DiscardLoginRedirect;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./index.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { setLoginPreview } from "../../redux/_actions/authAction";

const EmailVerification = (props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/verify-email-address/${props.match.params.email}`
      )
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="discord">
      {loading ? (
        <>
          <p>Verifying your email address! </p>
          <p>Please wait . . .</p>
        </>
      ) : (
        <>
          <p>Your email address is verified, you can login now!</p>
          <Button
            onClick={() => {
              dispatch(setLoginPreview(true));
              history.push("/");
            }}
          >
            Login
          </Button>
        </>
      )}
    </div>
  );
};

export default EmailVerification;

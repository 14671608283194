import React from "react";
import "./App.css";
import Route from "./routes/index";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-h5-audio-player/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { SocketContextProvider } from "./context/socket";
import { Helmet } from "react-helmet";
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          http-equiv="Content-Security-Policy"
          content="font-src https://fonts.googleapis.com https://fonts.gstatic.com; script-src 'self' 'unsafe-inline' 'unsafe-eval' https://www.youtube.com https://img.youtube.com https://plio-nlb-logs.s3.us-west-2.amazonaws.com https://accounts.google.com/gsi/client https://connect.facebook.net; style-src 'self' 'unsafe-inline' https://fonts.googleapis.com; Refused to load the image 'https://img.youtube.com/vi/ph98MeTCUMc/3.jpg' because it violates the following Content Security Policy directive: img-src 'self' data: https://cstechsoftwares.com https://s3-us-west-2.amazonaws.com  https://i.ytimg.com/ https://plio-nlb-logs.s3.us-west-2.amazonaws.com https://plio-nlb-logs.s3.amazonaws.com ; object-src 'none'; base-uri 'self';"
        />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <meta
          http-equiv="Strict-Transport-Security"
          content="max-age=31536000; includeSubDomains"
        />
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          // limit={1}
          position="top-right"
          style={{ zIndex: 9999999999 }}
          autoClose={2000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={false}
        />
        <SocketContextProvider>
          <Route />
        </SocketContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import VideoModal from "../../partails/videoModal";
import "./index.scss";

const CorporateFinance = () => {
  const history = useHistory();
  const back = () => {
    history.push(`/`);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>What is ESG Investing | Puzzicle</title>
        <meta name="description" content="What is ESG Investing | Puzzicle" />
      </Helmet>
      <VideoModal
        toggle={back}
        video="mAQ0YtW8BAI"
        channel="Corporate Finance Institute"
        title="What is ESG Investing | Puzzicle"
      />
    </>
  );
};
export default CorporateFinance;

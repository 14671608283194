import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import Controls from "./controls";
import { Button, Grid } from "@mui/material";
import LogoBox from "../../../logoBox";
import Link from "../../../../../../assets/icons/copyLink.svg";
import HtmlTag from "../../../../../../assets/icons/html-tag.svg";
import OpenEndedPin from "../../../../../../assets/icons/open-ended-pin.svg";
import CheckBoxPin from "../../../../../../assets/icons/checkbox-pin.svg";
import AudioPin from "../../../../../../assets/icons/audio-pin.svg";
import VideoQuestions from "../questions";
import VideoEnded from "../videoEnded";
import "./index.scss";
const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};
const CourseVideoPlayer = ({ linkHandler, video, questions, partylink }) => {
  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }
  const [nxtVidTime, setnxtVidTime] = useState(0);
  const [vidQues, setvidQues] = useState(null);
  const [vidMode, setvidMode] = useState(false);
  const [vidEndPreview, setvidEndPreview] = useState(false);
  const [state, setState] = useState({
    pip: false,
    playing: false,
    controls: false,
    light: false,
    muted: false,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
  });
  const copyLink = () => {
    linkHandler();
  };
  const embedLink = () => {
    const content = `<iframe src="${partylink}" title="Puzzicle video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    copyToClipboard(content);
  };
  const playerRef = useRef(null);
  const controlsRef = useRef(null);
  const { playing, light, muted, loop, playbackRate, pip, played, volume } =
    state;
  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);
  const handlePlayPause = async () => {
    if (!vidMode) {
      setvidEndPreview(false);
      setState({ ...state, playing: !state.playing });
    }
  };
  const handleProgress = (changeState) => {
    if (!state.seeking) {
      setState({ ...state, ...changeState });
      let ques = questions?.find(
        (x) => parseInt(x.time_marker) === parseInt(changeState.playedSeconds)
      );
      let quesTime = Math.min(
        questions?.find(
          (x) => parseInt(x.time_marker) > parseInt(changeState.playedSeconds)
        )?.time_marker
      );
      if (ques !== undefined) {
        setvidQues(ques);
        setState({ ...state, playing: false });
        setvidMode(true);
      }
      if (quesTime !== undefined) {
        setnxtVidTime(format(quesTime - changeState.playedSeconds));
      }
    }
  };
  const handleSeekChange = (e, newValue) => {
    setState({ ...state, played: parseFloat(newValue / 100) });
  };
  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };
  const handleSeekMouseUp = (e, newValue) => {
    if (!vidMode) {
      setState({ ...state, seeking: false });
      playerRef.current.seekTo(newValue / 100, "fraction");
    }
  };
  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    setvidQues(null);
    setState({ ...state, playing: true });
    setvidMode(false);
  };
  const handleSkip = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 1);
    setvidQues(null);
    setState({ ...state, playing: true });
    setvidMode(false);
  };
  const handleVolumeSeekDown = (e, newValue) => {
    setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  const handleVolumeChange = (e, newValue) => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };
  const handleEnded = () => {
    setvidEndPreview(true);
    // setvidMode(true);
    setState({ ...state, playing: false });
  };
  const setvidQuesHandle = () => {
    setvidQues(null);
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 1);
    setState({ ...state, playing: true });
    setvidMode(false);
  };

  useEffect(() => {
    setvidMode(false);
    setvidQues(null);
  }, []);
  return (
    <div className="course-video-player-wrapper">
      <div className="player-wrapper">
        <div className="video-player" onClick={handlePlayPause}>
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="100%"
            url={`https://www.youtube.com/watch?v=${video}`}
            pip={pip}
            playing={playing}
            style={{ pointerEvents: "none" }}
            controls={false}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            onEnded={handleEnded}
            muted={muted}
            onProgress={handleProgress}
            config={{
              file: {
                attributes: {
                  crossOrigin: "anonymous",
                },
              },
              youtube: {
                preload: true,
              },
            }}
          />
        </div>
        {vidQues && (
          <VideoQuestions
            data={vidQues}
            checkAns={setvidQuesHandle}
            // user={userdetail}
            skip={handleSkip}
            rewind={handleRewind}
          />
        )}
        {vidEndPreview && <VideoEnded />}
      </div>
      {playerRef?.current?.getDuration() && (
        <div className="video-markers">
          {questions?.map((item, i) => (
            <span
              key={i}
              style={{
                marginLeft: `${parseInt(
                  (Math.ceil(item.time_marker) * 100) /
                    playerRef?.current?.getDuration()
                )}%`,
              }}
            >
              <img
                src={
                  item.type === "open-ended"
                    ? OpenEndedPin
                    : item.type === "audio_note"
                    ? AudioPin
                    : item.type === "video_note"
                    ? AudioPin
                    : item.type === "multiple-choice"
                    ? CheckBoxPin
                    : Link
                }
                alt={item.type}
              />
            </span>
          ))}
        </div>
      )}
      <Controls
        ref={controlsRef}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        // onDuration={handleDuration}
        onPlayPause={handlePlayPause}
        playing={playing}
        played={played}
        vidMode={vidMode}
        elapsedTime={elapsedTime}
        totalDuration={totalDuration}
        muted={muted}
        onVolumeChange={handleVolumeChange}
        onVolumeSeekDown={handleVolumeSeekDown}
        volume={volume}
      />
      <div className="video-footer">
        <Grid container>
          <Grid item xs={3} md={4}>
            <div className="logo-box">
              <LogoBox link={false} />
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className="next-question">
              <label>Next Question {nxtVidTime}</label>
            </div>
          </Grid>
          <Grid item xs={3} md={4}>
            <div className="link-action-wrapper">
              <Button
                onClick={() => {
                  embedLink();
                }}
              >
                <img src={HtmlTag} alt="" />
                <span>Embed</span>
              </Button>
              <Button
                onClick={() => {
                  copyLink();
                }}
              >
                <img src={Link} alt="" />
                <span>Copy</span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default CourseVideoPlayer;

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserPrivateRoute from "../components/user/routing/privateRoute";
import ScrollToTop from "../components/scrollTop/index";
import ErrorPage from "../components/404/index";
import PartyOver from "../components/partyOver/index";
import ResetPassword from "../components/user/auth/resetPassword";
import CorporateFinance from "../components/user/pages/corportateFinance";
import BuildALesson from "../components/user/pages/buildalesson/index.jsx";
import Loader from "../components/loader";
import PrivacyPolicy from "../components/privacyPolicy";
import TermsOfService from "../components/TermsOfService";
import Support from "../components/Support";
import Documentation from "../components/Documentation";
import ZoomAppHome from "../components/ZoomAppHome";
import ZoomAppLanding from "../components/ZoomAppLanding";
import DiscardLoginRedirect from "../components/DiscardLoginRedirect";
import ZoomAppRedirectSuccess from "../components/ZoomAppRedirectSuccess";
import StripePayment from "../components/StripePayment";
import EmailVerification from "../components/EmailVerification/index.jsx";
import FacebookPrivacyPolicy from "../components/facebookPrivacyPolicy/index.jsx";

//lazy loaded components
const Home = lazy(() => import("../components/user/pages/home/index"));
const Landing = lazy(() => import("../components/user/pages/landing/index"));
const CreateStudyParty = lazy(() =>
  import("../components/user/pages/createParty")
);
const StudyParties = lazy(() => import("../components/user/pages/parties"));
const LiveParties = lazy(() => import("../components/user/pages/liveParty"));
const GradeDetails = lazy(() =>
  import("../components/user/pages/liveParty/gradeDetails")
);
const UnitDetails = lazy(() =>
  import("../components/user/pages/liveParty/unitDetails")
);
const CourseDetail = lazy(() =>
  import("../components/user/pages/liveParty/courseDetails")
);

const StudyParty = lazy(() => import("../components/user/pages/studyParty"));
const JoinZoomParty = lazy(() =>
  import("../components/user/pages/zoomParty/JoinZoomParty")
);
const SelectAvatarZoomParty = lazy(() =>
  import("../components/user/pages/zoomParty/selectAvatarZoomParty")
);
const StudyAlone = lazy(() => import("../components/user/pages/studyAlone"));
const AlreadyRunning = lazy(() =>
  import("../components/user/pages/studyParty/partials/alreadyRunning")
);
const khanAcademy = lazy(() =>
  import("../components/user/pages/khanAcademy/index")
);
const khanAcademyCourseDetail = lazy(() =>
  import("../components/user/pages/khanAcademy/partials/coursedetail/index")
);
const khanAcademyCourseVideo = lazy(() =>
  import("../components/user/pages/khanAcademy/partials/courseVideo/index")
);
const KhanVideoModal = lazy(() =>
  import("../components/user/pages/khanAcademy/partials/videoModal")
);
const CrashCourses = lazy(() =>
  import("../components/user/pages/crashCourses/")
);
const SingleCrashCourses = lazy(() =>
  import("../components/user/pages/crashCourses/partials/singleCourse/index")
);
const CrashVideoModal = lazy(() =>
  import("../components/user/pages/crashCourses/partials/videoModal")
);
const Youtube = lazy(() => import("../components/user/pages/youtube"));
const YoutubeCrashCourseKids = lazy(() =>
  import("../components/user/pages/youtube/partials/crashCourseKids.jsx")
);
const YoutubeCrashCourse = lazy(() =>
  import("../components/user/pages/youtube/partials/crashCourses")
);
const YoutubegeekGurlDiaries = lazy(() =>
  import("../components/user/pages/youtube/partials/geekGurlDiaries")
);
const YoutubegeoFocus = lazy(() =>
  import("../components/user/pages/youtube/partials/geoFocus")
);
const YoutubekhanAcademy = lazy(() =>
  import("../components/user/pages/youtube/partials/khanAcademy")
);
const YoutubemarkRober = lazy(() =>
  import("../components/user/pages/youtube/partials/markRober")
);
const YoutubemikeLikeScience = lazy(() =>
  import("../components/user/pages/youtube/partials/mikeLikeScience")
);
const YoutubenationalGeographics = lazy(() =>
  import("../components/user/pages/youtube/partials/nationalGeographics")
);
const YoutubenationalGeographyKids = lazy(() =>
  import("../components/user/pages/youtube/partials/nationalGeographyKids")
);
const Youtubenumberphile = lazy(() =>
  import("../components/user/pages/youtube/partials/numberphile")
);
const YoutubesoulPanCake = lazy(() =>
  import("../components/user/pages/youtube/partials/soulPanCake")
);
const YoutubetalentSearch = lazy(() =>
  import("../components/user/pages/youtube/partials/talentSearch")
);
const YoutubeVeritasium = lazy(() =>
  import("../components/user/pages/youtube/partials/Veritasium")
);
const YoutubeVideoModal = lazy(() =>
  import("../components/user/pages/youtube/partials/videoModal")
);

const Routes = () => {
  let youtubeRoutes = [
    { compon: YoutubeCrashCourseKids, route: "/crash-course-kids" },
    { compon: YoutubeCrashCourse, route: "/crash-course" },
    { compon: YoutubegeekGurlDiaries, route: "/geek-gurl-diaries" },
    { compon: YoutubegeoFocus, route: "/the-geo-focus-channel" },
    { compon: YoutubekhanAcademy, route: "/khanacademy" },
    { compon: YoutubemarkRober, route: "/mark-rober" },
    { compon: YoutubemikeLikeScience, route: "/mike-likes-science" },
    { compon: YoutubenationalGeographics, route: "/national-geographic" },
    {
      compon: YoutubenationalGeographyKids,
      route: "/national-geographic-kids",
    },
    { compon: Youtubenumberphile, route: "/numberphile" },
    { compon: YoutubesoulPanCake, route: "/soulpancake" },
    { compon: YoutubetalentSearch, route: "/ted" },
    { compon: YoutubeVeritasium, route: "/veritasium" },
  ];
  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <ScrollToTop />
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}
            <Route exact path="/" component={Landing} />
            <Route
              exact
              path="/resetpassword/:token"
              component={ResetPassword}
            />
            {/* CREATE PARTY ROUTES */}
            <UserPrivateRoute
              exact
              path="/createparty"
              component={CreateStudyParty}
            />
            {/* PARTIES ROUTE */}
            <Route exact path="/studyparty" component={StudyParties} />
            {/* LIVE PARTIES ROUTE */}
            <Route exact path="/liveparty" component={LiveParties} />
            <Route
              exact
              path="/liveparty/:coursetopic/:coursename"
              component={GradeDetails}
            />
            <Route
              exact
              path="/liveparty/:coursetopic/:coursename/:courseunit"
              component={UnitDetails}
            />

            <Route
              exact
              path="/liveparty/:coursetopic/:coursename/:courseunit/:course"
              component={CourseDetail}
            />
            <Route
              exact
              path="/liveparty/:coursetopic/:coursename/:courseunit/:course/:topic"
              component={KhanVideoModal}
            />

            {/* PARTY ROUTE */}
            <Route
              exact
              path={`/studyparty/:id/already-running`}
              component={AlreadyRunning}
            />
            <Route exact path={`/studyparty/:id`} component={StudyParty} />
            <Route exact path={`/redirect`} component={JoinZoomParty} />
            <Route
              exact
              path={`/selectAvatarZoomParty/:id`}
              component={SelectAvatarZoomParty}
            />
            {/* STUDY ALONE ROUTE */}
            <Route exact path={`/studyalone/:id`} component={StudyAlone} />
            {/* KHANACADEMY ROUTES */}
            <Route exact path="/khanacademy" component={khanAcademy} />
            <Route
              exact
              path="/khanacademy/:coursetopic"
              component={khanAcademyCourseDetail}
            />
            <Route
              exact
              path="/khanacademy/:coursetopic/:coursename"
              component={khanAcademyCourseDetail}
            />
            <Route
              exact
              path="/khanacademy/:coursetopic/:coursename/:courseunit"
              component={khanAcademyCourseVideo}
            />
            <Route
              exact
              path="/khanacademy/:coursetopic/:coursename/:courseunit/:topicslug"
              component={khanAcademyCourseVideo}
            />
            <Route
              exact
              path="/khanacademy/:coursetopic/:coursename/:courseunit/:topicslug/:topic"
              component={KhanVideoModal}
            />
            {/* CRASH COURSES ROUTES */}
            <Route exact path="/crashcourse" component={CrashCourses} />
            <Route
              exact
              path="/crashcourse/:id"
              component={SingleCrashCourses}
            />
            <Route
              exact
              path="/crashcourse/:id/:topic"
              component={CrashVideoModal}
            />
            {/* YOUTUBE ROUTES */}
            <Route exact path="/youtube" component={Youtube} />
            {youtubeRoutes.map((item, i) => (
              <Route
                key={i}
                exact
                path={`/youtube${item.route}`}
                component={item.compon}
              />
            ))}
            <Route
              exact
              path="/youtube/:id/:topic"
              component={YoutubeVideoModal}
            />
            <Route
              path="/corporate-finance-institute/what-is-esg-investing"
              component={CorporateFinance}
            />
            <Route path="/buildalesson/:id" component={BuildALesson} />
            {/* Privay Policy */}
            <Route path="/privacypolicy" component={PrivacyPolicy} />
            <Route
              path="/fb-privacy-policy"
              component={FacebookPrivacyPolicy}
            />
            <Route path="/terms" component={TermsOfService} />
            <Route path="/support" component={Support} />
            <Route path="/documentation" component={Documentation} />
            <Route path="/discord-redirect" component={DiscardLoginRedirect} />
            <Route
              path="/email-verification/:email"
              component={EmailVerification}
            />
            {/* ZOOM APP HOME*/}
            <Route path="/zoom-app-home" component={ZoomAppLanding} />
            <Route path="/zoom-app-landing" component={ZoomAppHome} />
            <Route
              path="/zoom-app-redirect-success"
              component={ZoomAppRedirectSuccess}
            />
            <Route path="/party-over" component={PartyOver} />
            {/* STRIPE PAYMENT ROUTE */}
            <Route path="/payments" component={StripePayment} />
            {/* 404 PAGE ROUTE */}
            <Route path="*" component={ErrorPage} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};
export default Routes;

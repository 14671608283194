import React from "react";
import Logo from "../../../../../../assets/puzzicle-logo.png";
import Confetti from "react-confetti";
import "./index.scss";

const VideoEnded = () => {
  return (
    <div className="course-video-ended-wrapper">
      <Confetti
        style={{ width: "100%", height: "100%" }}
        numberOfPieces={140}
      />
      <div className="logo-box">
        <img src={Logo} alt="puzzicle" />
      </div>
      <h2>Thank you</h2>
      <p>for going through the video</p>
    </div>
  );
};
export default VideoEnded;

import {
  CRASH_COURSE_MENU_SUCCESS,
  CRASH_COURSE_MENU_FAIL,
  CRASH_COURSE_SUCCESS,
  CRASH_COURSE_FAIL,
  CRASH_COURSE_VIDEO_SUCCESS,
  CRASH_COURSE_VIDEO_FAIL,
  CLEAR_ERRORS,
} from "../types";

const initState = {
  crashCouseMenu: [],
  crashCouse: [],
  crashCouseVideo: null,
  error: null,
  loading: true,
};

const CrashCourseReducer = (state = initState, action) => {
  switch (action.type) {
    case CRASH_COURSE_VIDEO_SUCCESS:
      return {
        ...state,
        crashCouseVideo: action.payload,
      };
    case CRASH_COURSE_VIDEO_FAIL:
      return {
        ...state,
        crashCouseVideo: null,
        error: action.payload,
      };
    case CRASH_COURSE_SUCCESS:
      return {
        ...state,
        crashCouse: action.payload,
      };
    case CRASH_COURSE_FAIL:
      return {
        ...state,
        crashCouse: [],
        error: action.payload,
      };
    case CRASH_COURSE_MENU_SUCCESS:
      return {
        ...state,
        crashCouseMenu: action.payload,
      };
    case CRASH_COURSE_MENU_FAIL:
      return {
        ...state,
        crashCouseMenu: [],
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default CrashCourseReducer;

export const AUTH_LOADING_SUCCESS = "AUTH_LOADING_SUCCESS";
export const AUTH_LOADING_FAIL = "AUTH_LOADING_FAIL";

export const SET_LOGIN_PREVIEW = "SET_LOGIN_PREVIEW";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const FORGET_SUCCESS = "FORGET_SUCCESS";
export const FORGET_FAIL = "FORGET_FAIL";

export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";

export const LOGOUT = "LOGOUT";

export const CRASH_COURSE_MENU_SUCCESS = "CRASH_COURSE_MENU_SUCCESS";
export const CRASH_COURSE_MENU_FAIL = "CRASH_COURSE_MENU_FAIL";

export const CRASH_COURSE_SUCCESS = "CRASH_COURSE_SUCCESS";
export const CRASH_COURSE_FAIL = "CRASH_COURSE_FAIL";

export const CRASH_COURSE_VIDEO_SUCCESS = "CRASH_COURSE_VIDEO_SUCCESS";
export const CRASH_COURSE_VIDEO_FAIL = "CRASH_COURSE_VIDEO_FAIL";

export const KHANACADEMY_VIDEO_SUCCESS = "KHANACADEMY_VIDEO_SUCCESS";
export const KHANACADEMY_VIDEO_FAIL = "KHANACADEMY_VIDEO_FAIL";

export const KHANACADEMY_MENU_SUCCESS = "KHANACADEMY_MENU_SUCCESS";
export const KHANACADEMY_MENU_FAIL = "KHANACADEMY_MENU_FAIL";

export const KHANACADEMY_MENU_DETAIL_SUCCESS =
  "KHANACADEMY_MENU_DETAIL_SUCCESS";
export const KHANACADEMY_MENU_DETAIL_FAIL = "KHANACADEMY_MENU_DETAIL_FAIL";

export const KHANACADEMY_UNIT_VIDEO_SUCCESS = "KHANACADEMY_UNIT_VIDEO_SUCCESS";
export const KHANACADEMY_UNIT_VIDEO_FAIL = "KHANACADEMY_UNIT_VIDEO_FAIL";

export const KHANACADEMY_TOPIC_VIDEO_SUCCESS =
  "KHANACADEMY_TOPIC_VIDEO_SUCCESS";
export const KHANACADEMY_TOPIC_VIDEO_FAIL = "KHANACADEMY_TOPIC_VIDEO_FAIL";

export const YOUTUBE_COURSE_SUCCESS = "YOUTUBE_COURSE_SUCCESS";
export const YOUTUBE_COURSE_FAIL = "YOUTUBE_COURSE_FAIL";

export const YOUTUBE_COURSE_VIDEO_SUCCESS = "YOUTUBE_COURSE_VIDEO_SUCCESS";
export const YOUTUBE_COURSE_VIDEO_FAIL = "YOUTUBE_COURSE_VIDEO_FAIL";

export const PARTY_LOADING_SUCCESS = "PARTY_LOADING_SUCCESS";
export const PARTY_LOADING_FAIL = "PARTY_LOADING_FAIL";

export const CREATE_PARTY_SUCCESS = "CREATE_PARTY_SUCCESS";
export const CREATE_PARTY_FAIL = "CREATE_PARTY_FAIL";

export const GET_PARTY_SUCCESS = "GET_PARTY_SUCCESS";
export const GET_PARTY_FAIL = "GET_PARTY_FAIL";

export const GET_SINGLE_PARTY_SUCCESS = "GET_SINGLE_PARTY_SUCCESS";
export const GET_SINGLE_PARTY_FAIL = "GET_SINGLE_PARTY_FAIL";

export const ACTIVATE_PARTY_SUCCESS = "ACTIVATE_PARTY_SUCCESS";
export const ACTIVATE_PARTY_FAIL = "ACTIVATE_PARTY_FAIL";

export const ACTIVATE_PARTY_PLAY_SUCCESS = "ACTIVATE_PARTY_PLAY_SUCCESS";
export const ACTIVATE_PARTY_PLAY_FAIL = "ACTIVATE_PARTY_PLAY_FAIL";

export const CREATE_PARTY_VIDEO_SUCCESS = "CREATE_PARTY_VIDEO_SUCCESS";
export const CREATE_PARTY_VIDEO_SUCCESS_REDUX =
  "CREATE_PARTY_VIDEO_SUCCESS_REDUX";
export const CREATE_PARTY_VIDEO_FAIL = "CREATE_PARTY_VIDEO_FAIL";

export const STUDY_LOADING_SUCCESS = "STUDY_LOADING_SUCCESS";
export const STUDY_LOADING_FAIL = "STUDY_LOADING_FAIL";

export const CREATE_STUDY_SUCCESS = "CREATE_STUDY_SUCCESS";
export const CREATE_STUDY_FAIL = "CREATE_STUDY_FAIL";

export const GET_SINGLE_STUDY_SUCCESS = "GET_SINGLE_STUDY_SUCCESS";
export const GET_SINGLE_STUDY_FAIL = "GET_SINGLE_STUDY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const DELETE_PARTY_VIDEO = "DELETE_PARTY_VIDEO";

export const CONTROL_USER_ACCESS = "CONTROL_USER_ACCESS";

export const LEAVE_PARTY_GRACEFULLY = "LEAVE_PARTY_GRACEFULLY";

export const VOLUME_CONTROL = "VOLUME_CONTROL";

export const START_PARTY = "START_PARTY";

export const KILL_PARTY = "KILL_PARTY";

export const GET_ZOOM_PARTY_SUCCESS = "GET_ZOOM_PARTY_SUCCESS";
export const GET_ZOOM_PARTY_FAIL = "GET_ZOOM_PARTY_FAIL";

export const SET_NO_ADMIN = "SET_NO_ADMIN";

export const SET_ADMIN_CONTROLS = "SET_ADMIN_CONTROLS";

export const SET_IS_HOST = "SET_IS_HOST";

export const SET_LEADERBOARD_DURATION = "SET_LEADERBOARD_DURATION";

export const SET_IS_QUESTION_PLAYING = "SET_IS_QUESTION_PLAYING";

export const ARE_QUESTIONS_LOADED = "ARE_QUESTIONS_LOADED";

export const SET_PLAYER_STATE = "SET_PLAYER_STATE";

export const SET_IS_1ST_QUESTION_PLAYED = "SET_IS_1ST_QUESTION_PLAYED";

export const SET_IS_THE_QUESTION_PLAYING = "SET_IS_THE_QUESTION_PLAYING";

export const SET_SEEK_TO_END = "SET_SEEK_TO_END";

export const SET_IS_LAST_QUESTION_PLAYED = "SET_IS_LAST_QUESTION_PLAYED";

export const SET_POLL_ANSWER = "SET_POLL_ANSWER";

export const SET_LAST_VIDEO_QUESTION = "SET_LAST_VIDEO_QUESTION";

export const SET_IS_SIDEBAR_REWIND = "SET_IS_SIDEBAR_REWIND";

export const SET_IS_PARTY_ENDED = "SET_IS_PARTY_ENDED";

export const RESET_PARTY = "RESET_PARTY";

export const GET_CURRENT_VIDEO_SUMMARY = "GET_CURRENT_VIDEO_SUMMARY";

export const SET_SKIP_ALL_QUESTION = "SET_SKIP_ALL_QUESTION";

export const SET_PLAYER_REF = "SET_PLAYER_REF";

export const SET_VIDEO_QUES = "SET_VIDEO_QUES";

export const SET_IS_QUESTION_TIMER_PLAYING = "SET_IS_QUESTION_TIMER_PLAYING";

export const SET_MAKE_ME_HOST = "SET_MAKE_ME_HOST";

export const SET_TOTAL_PARTY_PAGES = "SET_TOTAL_PARTY_PAGES";

export const GET_ALL_PARTY_SUCCESS = "GET_ALL_PARTY_SUCCESS";

export const RESET_PARTIES = "RESET_PARTIES";

export const SET_USER_ACCESS = "SET_USER_ACCESS";

export const SET_MESSAGE = "SET_MESSAGE";

export const SET_IS_1ST_QUESTION = "SET_IS_1ST_QUESTION";

export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";

export const GET_LIVE_PARTY_SUCCESS = "GET_LIVE_PARTY_SUCCESS";

export const RESET_LIVE_PARTY = "RESET_LIVE_PARTY";

export const SET_LIVE_PARTY = "SET_LIVE_PARTY";

export const SET_REGION = "SET_REGION";

export const SET_ADD_USER_SCORE = "SET_ADD_USER_SCORE";

export const SET_ANSWER_ALERT = "SET_ANSWER_ALERT";

export const SET_STUDY_PARTY_OPTION = "SET_STUDY_PARTY_OPTION";

export const SET_IS_PLAYING = "SET_IS_PLAYING";

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";

const Support = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Support | Puzzicle</title>
      </Helmet>
      <div className="Error">
        <div className="content">
          <h1>Puzzicle Support</h1>
          <p>
            Welcome to our support page! We're here to help with any questions
            or issues you may have. Please choose from the following options to
            get the support you need: Contact Us If you have any questions or
            concerns, please don't hesitate to contact us. You can reach us by
            email at account@puzzicle.com . We're available 24 hours to assist
            you.
          </p>
          <Link to="/" className="ripple">
            Back To Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Support;

import React from "react";
import Logo from "../../../../assets/puzzicle-logo.png";
import { Link } from "react-router-dom";

const LogoBox = ({ link }) => {
  const isRunningInZoom = () => {
    var userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes("zoom");
  };

  return link !== false ? (
    <Link to={isRunningInZoom() ? "/redirect" : "/"}>
      <img src={Logo} alt="Puzzicle" />
    </Link>
  ) : (
    <img src={Logo} alt="Puzzicle" />
  );
};
export default LogoBox;

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms of service | Puzzicle</title>
      </Helmet>
      <div className="Error">
        <div className="content">
          <p>
            Puzzicle is committed to protecting the privacy of its users. This
            Privacy Policy ("Policy") is designed to help you understand how we
            collect, use, and safeguard the information you provide to us and to
            assist you in making informed decisions when using our website and
            services.
          </p>
          <h3>Collection of Information</h3>
          <p>
            We collect the answers to quizzes only during the Zoom session. This
            is NOT personally identifiable information. And we do NOT retain
            even this non-personally identifiable information after the Zoom
            session.
          </p>
          <p>
            We do not collect any personally identifiable information. As such,
            we cannot retain, share or do anything with information we do not
            collect.
          </p>
          <p>
            We do not collect any personally identifiable information. As such,
            we cannot retain, share or do anything with information we do not
            collect. In that regard, there is no data to access for any
            customer.
          </p>
          <Link to="/" className="ripple">
            Back To Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;

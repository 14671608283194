import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Puzzicle from "../../assets/zoom/puzzicle.svg";
import Arrow from "../../assets/zoom/arrow.svg";
import Zoom from "../../assets/zoom/zoom.svg";
import "./index.scss";
import Logo from "../../assets/puzzicle-logo.png";

const ZoomAppHome = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zoom | Puzzicle</title>
      </Helmet>
      <div className="zoom-app-wraper">
        <div className="logoBox">
          <Link to="#">
            <img src={Logo} alt="Puzzicle" />
          </Link>
        </div>

        <h2>Puzzicle App for Zoom</h2>
        <p>
          Add Puzzicle App to Zoom and have a great learning experience with
          your Class
        </p>
        <div className="zoom-logo">
          <img src={Puzzicle} alt="" />
          <img src={Arrow} alt="" />
          <img src={Zoom} alt="" />
        </div>
        <a href="https://zoom.us/oauth/authorize?response_type=code&client_id=DkC1hkR4Q8mYEOJpEGr0JA&redirect_uri=https://puzzicle.org/zoom-app-landing">
          <button>Add Puzzicle App to Zoom</button>
        </a>
      </div>
    </>
  );
};

export default ZoomAppHome;

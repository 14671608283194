import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { getBuildALessonDetail } from "../../../../redux/_actions/youtubeCourseAction";
import VideoModal from "../../partails/videoModal";
import { Helmet } from "react-helmet";
import Loader from "../../../loader";

const BuildALesson = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = props?.match.params.id;
  const courseVideo = useSelector((state) => state.youtubeCourse.youtubeVideo);

  const back = () => {
    history.push(`/youtube`);
  };

  useEffect(() => {
    dispatch(getBuildALessonDetail(id));
  }, [dispatch]);

  return courseVideo?.data ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{courseVideo?.data?.name} | Puzzicle</title>
      </Helmet>
      <VideoModal
        toggle={back}
        video={courseVideo?.data?.video?.url}
        title={courseVideo?.data?.name}
        buildQuestions={courseVideo?.data?.items}
      />
    </>
  ) : (
    <Loader />
  );
};
export default BuildALesson;

import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import AudioPlayer from "react-h5-audio-player";
import Title from "../../../../../../assets/party/title.png";
import Confetti from "react-confetti";
import ReactPlayer from "react-player";
import "./index.scss";

const VideoQuestions = ({ data, rewind, checkAns, skip }) => {
  const questionInnerRef = useRef();
  const [optionLength, setoptionLength] = useState(false);
  const [optionTopLength, setoptionTopLength] = useState(false);
  const [choiceOption, setchoiceOption] = useState(false);
  const [answersPreview, setanswersPreview] = useState(false);
  const [answersMode, setanswersMode] = useState(true);
  const [userScorePreview, setuserScorePreview] = useState(true);
  const [videoQuesPreview, setvideoQuesPreview] = useState(false);
  const checkAnswer = async () => {
    if (data.type === "multiple-choice") {
      const checkedBoxes = document.querySelectorAll(
        "input[class=question-choice]:checked"
      );
      const input = document.getElementsByClassName("question-choice");
      setanswersPreview(true);
      setanswersMode(false);
      if (checkedBoxes.length !== data.answers.length) {
        setuserScorePreview(false);
      } else {
        for (let i = 0; i < input.length; i++) {
          if (input[i].checked === true) {
            if (!data.answers.includes(i)) {
              setuserScorePreview(false);
            }
          }
        }
      }
    } else {
      checkAns();
    }
  };
  const submitAnswer = () => {
    checkAns();
  };
  const rewatchVideo = () => {
    rewind();
  };
  const skipQuestion = () => {
    skip();
  };
  const setchoiceOptionHandler = () => {
    setchoiceOption(false);
    if (answersPreview === true) {
      return false;
    } else {
      const input = document.getElementsByClassName("question-choice");
      for (let i = 0; i < input.length; i++) {
        if (input[i].checked === true) {
          setchoiceOption(true);
        }
      }
    }
  };
  const scrollToBottom = () => {
    var target = document.getElementById("options");
    if (target) {
      target.parentNode.scrollTop = target.offsetTop;
    }
  };
  const scrollToTop = () => {
    var target = document.getElementById("option1");
    if (target) {
      target.parentNode.scrollTop = target.offsetTop;
    }
  };
  useEffect(() => {
    if (questionInnerRef.current) {
      const { scrollHeight, clientHeight } = questionInnerRef.current;
      const hasScrollableContent = scrollHeight > clientHeight;
      setoptionLength(hasScrollableContent);
    }
  }, [questionInnerRef]);
  const onScroll = () => {
    if (questionInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        questionInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setoptionLength(false);
        setoptionTopLength(true);
      } else {
        setoptionLength(true);
        setoptionTopLength(false);
      }
    }
  };
  return (
    <div className="course-video-question-wrapper">
      {data.type === "multiple-choice" ? (
        <>
          <div className="title">
            <img src={Title} alt="" />
          </div>
          <h2
            className="question"
            dangerouslySetInnerHTML={{ __html: data.question }}
          ></h2>
          <div className="options-wrapper">
            <ul className="options" ref={questionInnerRef} onScroll={onScroll}>
              {data?.choice.map((item, i) => (
                <li
                  key={i}
                  id={`${
                    i === 0
                      ? "option1"
                      : i === data?.choice.length - 1
                      ? "options"
                      : ""
                  }`}
                >
                  <label
                    className={
                      answersPreview
                        ? "checkbox-container active"
                        : "checkbox-container "
                    }
                  >
                    {answersPreview ? (
                      <>
                        {userScorePreview ? (
                          <label
                            dangerouslySetInnerHTML={{ __html: item.content }}
                            style={{
                              color:
                                data?.answers?.find((x) => x === i) > -1
                                  ? "#00BAC7"
                                  : "",
                            }}
                          />
                        ) : (
                          <label
                            dangerouslySetInnerHTML={{ __html: item.content }}
                            style={{
                              color:
                                data?.answers?.find((x) => x === i) > -1
                                  ? "#00BAC7"
                                  : "#F63C8F",
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <label
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    )}
                    <input
                      type="checkbox"
                      disabled={!answersMode ? true : false}
                      className="question-choice"
                      value={item.content}
                      onClick={() => {
                        setchoiceOptionHandler();
                      }}
                    />
                    {answersPreview ? (
                      <>
                        {userScorePreview ? (
                          <span
                            className="checkmark active"
                            style={{
                              background:
                                data?.answers?.find((x) => x === i) > -1
                                  ? "#00BAC7"
                                  : "",
                              border:
                                data?.answers?.find((x) => x === i) > -1
                                  ? "1px solid #00BAC7"
                                  : "1px solid transparent",
                            }}
                          ></span>
                        ) : (
                          <span
                            className="checkmark active"
                            style={{
                              background:
                                data?.answers?.find((x) => x === i) > -1
                                  ? "#00BAC7"
                                  : "#F63C8F",
                              border:
                                data?.answers?.find((x) => x === i) > -1
                                  ? "1px solid #00BAC7"
                                  : "1px solid #F63C8F",
                            }}
                          ></span>
                        )}
                      </>
                    ) : (
                      <span className="checkmark"></span>
                    )}
                  </label>
                </li>
              ))}
            </ul>
            {optionTopLength === true && (
              <span
                className="scroll-up"
                onClick={() => {
                  scrollToTop();
                }}
              ></span>
            )}
            {optionLength === true && (
              <span
                className="scroll-down"
                onClick={() => {
                  scrollToBottom();
                }}
              ></span>
            )}
          </div>
        </>
      ) : data.type === "video_note" ? (
        <div className="open-ended">
          {!videoQuesPreview ? (
            <div>
              <ReactPlayer
                className="ques-video-wrapper"
                playing={true}
                controls={true}
                // url={`${data.video}?&start=400&end=500`}
                url={data.video}
                onEnded={() => {
                  setvideoQuesPreview(true);
                }}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "anonymous",
                    },
                  },
                  youtube: {
                    preload: true,
                  },
                }}
              />
              {/* <iframe
                className="ques-video-wrapper"
                src="https://www.youtube.com/embed/mAQ0YtW8BAI?start=31&end=40"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe> */}
            </div>
          ) : (
            <>
              <div className="title">
                <img
                  src="https://s3-us-west-2.amazonaws.com/assets.puzzicle.com/thinking-face.png"
                  alt=""
                />
                {data.question}
              </div>
              {/* <h2
                className="question"
                dangerouslySetInnerHTML={{ __html: data.question }}
              ></h2> */}
              <ul className="options">
                <li style={{ marginTop: "60px" }}>
                  <label className="checkbox-container">
                    <label>
                      <img
                        className="thinking"
                        src="https://s3-us-west-2.amazonaws.com/assets.puzzicle.com/emoji.png"
                        alt=""
                      />
                      Yes I'm done thinking
                    </label>
                    <input
                      type="checkbox"
                      className="question-choice"
                      value="yes"
                      onClick={() => {
                        setchoiceOptionHandler();
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </>
          )}
        </div>
      ) : data.type === "audio_note" ? (
        <div className="open-ended">
          <div className="title">
            <img
              src="https://s3-us-west-2.amazonaws.com/assets.puzzicle.com/thinking-face.png"
              alt=""
            />
            Think, Recall, Internalize
          </div>
          <div className="audio-wrapper">
            <AudioPlayer autoPlay src={data.question} />
          </div>
          <ul className="options">
            <li>
              <label className="checkbox-container">
                <label>
                  <img
                    className="thinking"
                    src="https://s3-us-west-2.amazonaws.com/assets.puzzicle.com/emoji.png"
                    alt=""
                  />
                  Yes I'm done thinking
                </label>
                <input
                  type="checkbox"
                  className="question-choice"
                  value="yes"
                  onClick={() => {
                    setchoiceOptionHandler();
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </li>
          </ul>
        </div>
      ) : (
        <div className="open-ended">
          <div className="title">
            <img
              src="https://s3-us-west-2.amazonaws.com/assets.puzzicle.com/thinking-face.png"
              alt=""
            />
            Think, Recall, Internalize
          </div>
          <h2
            className="question"
            dangerouslySetInnerHTML={{ __html: data.question }}
          ></h2>
          <ul className="options">
            <li>
              <label className="checkbox-container">
                <label>
                  <img
                    className="thinking"
                    src="https://s3-us-west-2.amazonaws.com/assets.puzzicle.com/emoji.png"
                    alt=""
                  />
                  Yes I'm done thinking
                </label>
                <input
                  type="checkbox"
                  className="question-choice"
                  value="yes"
                  onClick={() => {
                    setchoiceOptionHandler();
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </li>
          </ul>
        </div>
      )}
      <div className="button-wrapper">
        <Button
          onClick={() => {
            rewatchVideo();
          }}
        >
          Rewatch -15s
        </Button>
        {!answersPreview ? (
          <>
            {choiceOption ? (
              <Button
                onClick={() => {
                  checkAnswer();
                }}
              >
                Check Answer
              </Button>
            ) : (
              <Button style={{ opacity: "0.3" }} disabled>
                Check Answer
              </Button>
            )}
          </>
        ) : (
          <Button
            onClick={() => {
              submitAnswer();
            }}
          >
            Resume Video
          </Button>
        )}
        <Button
          className="skip-question"
          onClick={() => {
            skipQuestion();
          }}
        >
          Skip Question <ChevronRight />
        </Button>
      </div>

      {answersPreview && (
        <>
          {userScorePreview && (
            <Confetti style={{ width: "100%", height: "100%" }} />
          )}
        </>
      )}
    </div>
  );
};
export default VideoQuestions;

import { useEffect, useState, createContext } from "react";
import io from "socket.io-client";

export const SocketContext = createContext();

export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_SOCKET_CON}`, {
      transports: ["websocket"],
      closeOnBeforeunload: false,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 5,
    });

    setSocket(newSocket);

    // Handle disconnection and reconnection
    newSocket.on("disconnect", () => {
      window.location.reload();
      console.log("Disconnected from the server");
    });
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket: socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

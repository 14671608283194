import React from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import "./index.scss";
import Logo from "../../assets/puzzicle-logo.png";

import zoomSdk from "@zoom/appssdk";

async function configureApp() {
  const configResponse = await zoomSdk.config({
    popoutURL: "https://puzzicle.org",
    popoutSize: { width: 480, height: 360 },
    capabilities: ["openUrl"],
  });

  // Handle the configuration response as needed
  console.log("zoom config response", configResponse);
}

const ZoomAppLanding = () => {
  configureApp();
  const history = useHistory();

  const externalUrl = async () => {
    window.zoomSdk.openUrl({ url: "https://puzzicle.org" }).then((result) => {
      history.push("/zoom-app-redirect-success");
    });
  };

  // const getAccessToken = async (authorizationCode) => {
  //   const tokenUrl = "https://zoom.us/oauth/token";
  //   const params = {
  //     grant_type: "authorization_code",
  //     code: authorizationCode,
  //     client_id: "YOUR_CLIENT_ID",
  //     client_secret: "YOUR_CLIENT_SECRET",
  //     redirect_uri: "YOUR_REDIRECT_URI",
  //   };

  //   try {
  //     const response = await axios.post(tokenUrl, null, { params });
  //     const { access_token: token } = response.data;
  //     // setAccessToken(token);
  //   } catch (error) {
  //     console.error("Failed to obtain access token:", error);
  //   }
  // };

  // useEffect(() => {
  //   // Extract authorization code from URL after redirect
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const authorizationCode = urlParams.get("code");

  //   if (authorizationCode) {
  //     getAccessToken(authorizationCode);
  //   }
  // }, []);

  // const redirectToZoom = () => {
  //   // Step 1: Obtain authorization code
  //   const authorizationUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URI`;

  //   // Redirect the user to the Zoom authorization URL
  //   window.location.href = authorizationUrl;
  // };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zoom | Puzzicle</title>
      </Helmet>
      <div className="zoom-app-landing-wraper">
        <div className="zoom-logo">
          <Link to="#">
            <img src={Logo} alt="Puzzicle" />
          </Link>
        </div>

        <h2>Make your Zoom meetings awesome!</h2>

        <div className="button-group">
          <Link to={"/redirect"}>
            <button>
              <span>Join as a participant</span>
              <span>Join a Puzzicle that someone's hosting.</span>
            </button>
          </Link>

          <button onClick={() => externalUrl()}>
            <span>Host a Puzzicle</span>
            <span>Log in or create a free account at puzzicle.org</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default ZoomAppLanding;

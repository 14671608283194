import {
  YOUTUBE_COURSE_SUCCESS,
  YOUTUBE_COURSE_FAIL,
  YOUTUBE_COURSE_VIDEO_SUCCESS,
  YOUTUBE_COURSE_VIDEO_FAIL,
  CLEAR_ERRORS,
} from "../types";

const initState = {
  youtubeCourse: [],
  youtubeVideo: null,
  error: null,
  loading: true,
};

const YoutubeCourseReducer = (state = initState, action) => {
  switch (action.type) {
    case YOUTUBE_COURSE_SUCCESS:
      return {
        ...state,
        youtubeCourse: action.payload,
      };
    case YOUTUBE_COURSE_FAIL:
      return {
        ...state,
        youtubeCourse: [],
        error: action.payload,
      };
    case YOUTUBE_COURSE_VIDEO_SUCCESS:
      return {
        ...state,
        youtubeVideo: action.payload,
      };
    case YOUTUBE_COURSE_VIDEO_FAIL:
      return {
        ...state,
        youtubeVideo: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default YoutubeCourseReducer;

import {
  PARTY_LOADING_SUCCESS,
  PARTY_LOADING_FAIL,
  CREATE_PARTY_SUCCESS,
  CREATE_PARTY_FAIL,
  GET_PARTY_SUCCESS,
  GET_PARTY_FAIL,
  GET_SINGLE_PARTY_SUCCESS,
  GET_SINGLE_PARTY_FAIL,
  ACTIVATE_PARTY_SUCCESS,
  ACTIVATE_PARTY_FAIL,
  ACTIVATE_PARTY_PLAY_SUCCESS,
  ACTIVATE_PARTY_PLAY_FAIL,
  CREATE_PARTY_VIDEO_SUCCESS,
  CREATE_PARTY_VIDEO_FAIL,
  CLEAR_ERRORS,
  DELETE_PARTY_VIDEO,
  LEAVE_PARTY_GRACEFULLY,
  VOLUME_CONTROL,
  START_PARTY,
  KILL_PARTY,
  GET_ZOOM_PARTY_SUCCESS,
  SET_NO_ADMIN,
  SET_ADMIN_CONTROLS,
  SET_IS_HOST,
  SET_LEADERBOARD_DURATION,
  SET_IS_QUESTION_PLAYING,
  ARE_QUESTIONS_LOADED,
  SET_SEEK_TO_END,
  SET_IS_1ST_QUESTION_PLAYED,
  SET_IS_THE_QUESTION_PLAYING,
  SET_IS_PARTY_ENDED,
  SET_IS_LAST_QUESTION_PLAYED,
  SET_LAST_VIDEO_QUESTION,
  RESET_PARTY,
  GET_CURRENT_VIDEO_SUMMARY,
  CREATE_PARTY_VIDEO_SUCCESS_REDUX,
  SET_SKIP_ALL_QUESTION,
  SET_PLAYER_REF,
  SET_VIDEO_QUES,
  SET_IS_QUESTION_TIMER_PLAYING,
  SET_IS_SIDEBAR_REWIND,
  SET_MAKE_ME_HOST,
  SET_TOTAL_PARTY_PAGES,
  GET_ALL_PARTY_SUCCESS,
  RESET_PARTIES,
  SET_USER_ACCESS,
  SET_IS_1ST_QUESTION,
  SET_LIVE_PARTY,
  SET_ADD_USER_SCORE,
  SET_ANSWER_ALERT,
  SET_IS_PLAYING,
} from "../types";

const initState = {
  loading: false,
  error: null,
  message: null,
  success: null,
  parties: [],
  party: {
    videos: [],
  },
  funnyVideos: [],
  leavePartyGracefully: false,
  volumeControl: false,
  startParty: false,
  killPartyControl: true,
  zoomParty: null,
  noAdmin: false,
  adminControls: false,
  isHost: false,
  leaderBoardDuration: 30,
  isQuestionPlaying: true,
  areQuestionsLoaded: false,
  is1stQuestionPlayed: true,
  isTheQuestionPlaying: false,
  isPartyEnded: false,
  isLastQuestionPlayed: false,
  lastVideoQuestion: null,
  seekToEnd: false,
  currentVideoSummary: "",
  skipAllQuestion: false,
  playerRef: null,
  vidQues: null,
  isQuestionTimerPlaying: true,
  isSidebarRewind: false,
  makeMeHost: false,
  totalPartyPages: null,
  userAccess: false,
  is1stQuestion: false,
  answerAlert: false,
  addUserScore: false,
  isVideoPlaying: false,
};

const partyReducer = (state = initState, action) => {
  switch (action.type) {
    case PARTY_LOADING_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case PARTY_LOADING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PARTY_SUCCESS:
    case CREATE_PARTY_VIDEO_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case CREATE_PARTY_VIDEO_SUCCESS_REDUX:
      return {
        ...state,
        party: {
          ...state.party,
          videos: [...state.party.videos, ...action.payload],
        },
      };

    case GET_PARTY_SUCCESS:
      return {
        ...state,
        parties: action.payload.data,
      };
    case SET_LIVE_PARTY:
      return {
        ...state,
        parties: action.payload,
      };

    case GET_ALL_PARTY_SUCCESS:
      return {
        ...state,
        parties: [...state.parties, ...action.payload.data],
      };

    case GET_PARTY_FAIL:
      return {
        ...state,
        parties: [],
      };
    case GET_SINGLE_PARTY_SUCCESS:
      return {
        ...state,
        party: action.payload.data,
        success: true,
      };
    case RESET_PARTY:
      return {
        ...state,
        party: action.payload.data,
      };
    case GET_SINGLE_PARTY_FAIL:
      return {
        ...state,
        party: action.payload.message,
        success: false,
      };
    case ACTIVATE_PARTY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case ACTIVATE_PARTY_PLAY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case DELETE_PARTY_VIDEO:
      return {
        ...state,
        party: {
          ...state.party,
          videos: action.payload,
        },
      };
    case LEAVE_PARTY_GRACEFULLY:
      return {
        ...state,
        leavePartyGracefully: action.payload,
      };
    case VOLUME_CONTROL:
      return {
        ...state,
        volumeControl: action.payload,
      };
    case START_PARTY:
      return {
        ...state,
        startParty: action.payload,
      };
    case KILL_PARTY:
      return {
        ...state,
        killPartyControl: action.payload,
      };
    case GET_ZOOM_PARTY_SUCCESS:
      return {
        ...state,
        zoomParty: action.payload,
      };
    case SET_NO_ADMIN:
      return {
        ...state,
        noAdmin: action.payload,
      };
    case SET_ADMIN_CONTROLS:
      return {
        ...state,
        adminControls: action.payload,
      };
    case SET_IS_HOST:
      return {
        ...state,
        isHost: action.payload,
      };
    case SET_LEADERBOARD_DURATION:
      return {
        ...state,
        leaderBoardDuration: action.payload,
      };
    case SET_IS_QUESTION_PLAYING:
      return {
        ...state,
        isQuestionPlaying: action.payload,
      };
    case ARE_QUESTIONS_LOADED:
      return {
        ...state,
        areQuestionsLoaded: action.payload,
      };
    case SET_IS_1ST_QUESTION_PLAYED:
      return {
        ...state,
        is1stQuestionPlayed: action.payload,
      };
    case SET_IS_THE_QUESTION_PLAYING:
      return {
        ...state,
        isTheQuestionPlaying: action.payload,
      };
    case SET_IS_PARTY_ENDED:
      return {
        ...state,
        isPartyEnded: action.payload,
      };
    case SET_IS_LAST_QUESTION_PLAYED:
      return {
        ...state,
        isLastQuestionPlayed: action.payload,
      };
    case SET_LAST_VIDEO_QUESTION:
      return {
        ...state,
        lastVideoQuestion: action.payload,
      };
    case SET_IS_SIDEBAR_REWIND:
      return {
        ...state,
        isSidebarRewind: action.payload,
      };
    case SET_MAKE_ME_HOST:
      return {
        ...state,
        makeMeHost: action.payload,
      };

    case SET_SEEK_TO_END:
      return {
        ...state,
        seekToEnd: action.payload,
      };

    case GET_CURRENT_VIDEO_SUMMARY:
      return {
        ...state,
        currentVideoSummary: action.payload,
      };

    case SET_SKIP_ALL_QUESTION:
      return {
        ...state,
        skipAllQuestion: action.payload,
      };

    case SET_PLAYER_REF:
      return {
        ...state,
        playerRef: action.payload,
      };

    case SET_VIDEO_QUES:
      return {
        ...state,
        vidQues: action.payload,
      };

    case SET_IS_QUESTION_TIMER_PLAYING:
      return {
        ...state,
        isQuestionTimerPlaying: action.payload,
      };
    case SET_TOTAL_PARTY_PAGES:
      return {
        ...state,
        totalPartyPages: action.payload,
      };
    case RESET_PARTIES:
      return {
        ...state,
        parties: [],
      };
    case SET_USER_ACCESS:
      return {
        ...state,
        userAccess: action.payload,
      };
    case SET_IS_1ST_QUESTION:
      return {
        ...state,
        is1stQuestion: action.payload,
      };

    case SET_ADD_USER_SCORE:
      return {
        ...state,
        addUserScore: action.payload,
      };
    case SET_ANSWER_ALERT:
      return {
        ...state,
        answerAlert: action.payload,
      };
    case SET_IS_PLAYING:
      return {
        ...state,
        isVideoPlaying: action.payload,
      };

    case CREATE_PARTY_FAIL:
    case CREATE_PARTY_VIDEO_FAIL:
    case ACTIVATE_PARTY_FAIL:
    case ACTIVATE_PARTY_PLAY_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default partyReducer;

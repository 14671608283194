import { combineReducers } from "redux";
import crashCourseReducer from "./crashCourseReducer";
import khanAcademyCoursesReducer from "./khanAcademyCoursesReducer";
import authReducer from "./authReducer";
import studyPartyReducer from "./studyPartyReducer";
import studyAloneReducer from "./studyAloneReducer";
import YoutubeCourseReducer from "./youtubeCourseReducer";
import LivePartyReducer from "./livePartyReducer";
export default combineReducers({
  crashCourse: crashCourseReducer,
  youtubeCourse: YoutubeCourseReducer,
  auth: authReducer,
  studyParty: studyPartyReducer,
  studyAlone: studyAloneReducer,
  khanAcademyCourse: khanAcademyCoursesReducer,
  liveParty: LivePartyReducer,
});

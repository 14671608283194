import {
  YOUTUBE_COURSE_SUCCESS,
  YOUTUBE_COURSE_FAIL,
  YOUTUBE_COURSE_VIDEO_SUCCESS,
  YOUTUBE_COURSE_VIDEO_FAIL,
} from "../types";
import axios from "axios";
import * as Sentry from "@sentry/react";

export const getYoutubeCourse = () => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/api/youtube`,
        config
      );

      dispatch({ type: YOUTUBE_COURSE_SUCCESS, payload: res?.data });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({
        type: YOUTUBE_COURSE_FAIL,
        payload: err?.response?.data?.message,
      });
    }
  };
};

export const getYoutubeCourseVideo = (topic) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENV}/api/youtube/videodetail/${topic}`,
        config
      );

      dispatch({ type: YOUTUBE_COURSE_VIDEO_SUCCESS, payload: res?.data });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({
        type: YOUTUBE_COURSE_VIDEO_FAIL,
        payload: err?.response?.data?.message,
      });
    }
  };
};

export const getBuildALessonDetail = (id) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `https://backend.buildalesson.org/api/v1/plios/${id}/play/`,
        config
      );

      const result = {
        success: true,
        data: res?.data,
      };

      dispatch({ type: YOUTUBE_COURSE_VIDEO_SUCCESS, payload: result });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({
        type: YOUTUBE_COURSE_VIDEO_FAIL,
        payload: err?.response?.data?.message,
      });
    }
  };
};

import {
  NEXT_PAGE,
  PREVIOUS_PAGE,
  SET_REGION,
  SET_STUDY_PARTY_OPTION,
} from "../types";

const initState = {
  partyPage: [
    {
      name: "Main Page",
      link: "/liveparty",
    },
  ],
  region: localStorage.getItem("region"),
  studyPartyOption: {
    name: "Join Party",
  },
};

const livePartyReducer = (state = initState, action) => {
  switch (action.type) {
    case NEXT_PAGE:
      localStorage.setItem(
        "partyPage",
        JSON.stringify([...state.partyPage, action.payload])
      );

      if (state.partyPage.length > 1 && action.payload.index == 0) {
        return {
          ...state,
          partyPage: [state.partyPage[0], action.payload],
        };
      } else {
        return {
          ...state,
          partyPage: [...state.partyPage, action.payload],
        };
      }

    case PREVIOUS_PAGE:
      const updatedPartyPage = [...state.partyPage];
      updatedPartyPage.pop();
      if (action.payload == 0) {
        return {
          ...state,
          partyPage: [
            {
              name: "Main Page",
              link: "/liveparty",
              index: 0,
            },
          ],
        };
      } else {
        return {
          ...state,
          partyPage: updatedPartyPage,
        };
      }

    case SET_REGION:
      localStorage.setItem("region", action.payload);
      return {
        ...state,
        region: action.payload,
      };
    case SET_STUDY_PARTY_OPTION:
      return {
        ...state,
        studyPartyOption: action.payload,
      };

    default:
      return state;
  }
};

export default livePartyReducer;

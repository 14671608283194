import React from "react";
import { Helmet } from "react-helmet";
import "./index.scss";
import LogoBox from "../user/partails/logoBox";
import { Link } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";

const ZoomAppRedirectSuccess = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zoom | Puzzicle</title>
      </Helmet>
      <div className="zoom-app-redrict-wraper">
        <div className="back-btn">
          <Link to="/zoom-app-home">
            <ArrowBack className="btn" />
          </Link>
        </div>
        <div className="logoBox">
          <LogoBox />
        </div>

        <h2>Make your Zoom meetings awesome!</h2>

        <p>
          You need to create a party on your machine browser and share your
          screen!
        </p>
      </div>
    </>
  );
};
export default ZoomAppRedirectSuccess;

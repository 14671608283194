import {
  KHANACADEMY_VIDEO_SUCCESS,
  KHANACADEMY_VIDEO_FAIL,
  KHANACADEMY_MENU_SUCCESS,
  KHANACADEMY_MENU_FAIL,
  KHANACADEMY_MENU_DETAIL_SUCCESS,
  KHANACADEMY_MENU_DETAIL_FAIL,
  KHANACADEMY_UNIT_VIDEO_SUCCESS,
  KHANACADEMY_UNIT_VIDEO_FAIL,
  KHANACADEMY_TOPIC_VIDEO_SUCCESS,
  KHANACADEMY_TOPIC_VIDEO_FAIL,
  CLEAR_ERRORS,
} from "../types";

const initState = {
  khanAcademyMenu: [],
  khanAcademyUnitMenu: [],
  khanAcademyVideo: null,
  error: null,
  loading: true,
};

const KhanAcademyReducer = (state = initState, action) => {
  switch (action.type) {
    case KHANACADEMY_VIDEO_SUCCESS:
      return {
        ...state,
        khanAcademyVideo: action.payload,
      };
    case KHANACADEMY_VIDEO_FAIL:
      return {
        ...state,
        khanAcademyVideo: null,
        error: action.payload,
      };
    case KHANACADEMY_MENU_SUCCESS:
    case KHANACADEMY_MENU_DETAIL_SUCCESS:
      return {
        ...state,
        khanAcademyMenu: action.payload,
      };
    case KHANACADEMY_MENU_DETAIL_FAIL:
    case KHANACADEMY_MENU_FAIL:
      return {
        ...state,
        khanAcademyMenu: [],
        error: action.payload,
      };
    case KHANACADEMY_UNIT_VIDEO_SUCCESS:
    case KHANACADEMY_TOPIC_VIDEO_SUCCESS:
      return {
        ...state,
        khanAcademyUnitMenu: action.payload,
      };
    case KHANACADEMY_UNIT_VIDEO_FAIL:
    case KHANACADEMY_TOPIC_VIDEO_FAIL:
      return {
        ...state,
        khanAcademyUnitMenu: [],
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default KhanAcademyReducer;

import React from "react";
// import LoaderIcon from "../../assets/loader.gif";
import "./index.scss";

const Loader = ({ size }) => {
  return (
    <div className={`loading-screen ${size === "small" ? " small" : ""}`}>
      Loading
      <div className="dot-typing"></div>
    </div>
  );
};

export default Loader;

import {
  AUTH_LOADING_SUCCESS,
  AUTH_LOADING_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  FORGET_SUCCESS,
  FORGET_FAIL,
  RESET_SUCCESS,
  RESET_FAIL,
  LOGOUT,
  CONTROL_USER_ACCESS,
  SET_LOGIN_PREVIEW,
  RESET_PARTIES,
  SET_USER_ACCESS,
  SET_MESSAGE,
} from "../types";
import axios from "axios";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

export const login = (crediential, partyLink, partyOwner) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      dispatch({ type: AUTH_LOADING_SUCCESS, payload: true });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/login`,
        crediential,
        config
      );
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("loggedInUser", JSON.stringify(res.data.user));
      if (partyLink && partyOwner !== res.data.token) {
        return;
      } else if (partyLink) {
        window.location.replace(partyLink);
      } else {
        window.location.replace("/liveparty");
      }
    } catch (err) {
      Sentry.captureException(err);
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      toast.error(err?.response?.data.message);
      dispatch({ type: LOGIN_FAIL, payload: err.response?.data.message });
    }
  };
};

export const loginWithGoogle = (crediential) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      dispatch({ type: AUTH_LOADING_SUCCESS, payload: true });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/googleLogin`,
        crediential,
        config
      );
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      // dispatch({ type: SET_LOGIN_PREVIEW, payload: false });
      // dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("loggedInUser", JSON.stringify(res.data.user));

      window.location.replace("/liveparty");
    } catch (err) {
      Sentry.captureException(err);
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      toast.error(err?.response?.data.message);
      dispatch({ type: LOGIN_FAIL, payload: err.response?.data.message });
    }
  };
};

export const loginWithFacebook = (crediential) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      dispatch({ type: AUTH_LOADING_SUCCESS, payload: true });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/facebookLogin`,
        crediential,
        config
      );
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      // dispatch({ type: SET_LOGIN_PREVIEW, payload: false });
      // dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("loggedInUser", JSON.stringify(res.data.user));

      window.location.replace("/liveparty");
    } catch (err) {
      Sentry.captureException(err);
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      toast.error(err?.response?.data.message);
      dispatch({ type: LOGIN_FAIL, payload: err.response?.data.message });
    }
  };
};

export const register = (user) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      dispatch({ type: AUTH_LOADING_SUCCESS, payload: true });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/register`,
        user,
        config
      );
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      // dispatch(login(user));
      // toast.info(
      //   "Thank you for registering! To complete your account setup, please check your email and follow the verification instructions we've sent to you."
      // );
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      toast.error(err?.response?.data.message);
      dispatch({ type: REGISTER_FAIL, payload: err.response?.data });
    }
  };
};
export const forgetPassword = (email) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    dispatch({ type: AUTH_LOADING_SUCCESS, payload: true });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/forgetpassword`,
        { email },
        config
      );
      // toast.success(res?.data?.message);
      setTimeout(() => {
        // window.location.reload();
        dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      }, 2000);
      dispatch({ type: FORGET_SUCCESS, payload: res.data });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      toast.error(err?.response?.data.message);
      dispatch({ type: FORGET_FAIL, payload: err.response.data });
    }
  };
};
export const resetPassword = (token, newPassword) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      dispatch({ type: AUTH_LOADING_SUCCESS, payload: true });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENV}/api/auth/resetpassword/${token}`,
        { newPassword },
        config
      );
      toast.success(res?.data?.message);

      setTimeout(() => {
        window.location.replace("/");
        dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      }, 2000);
      dispatch({ type: RESET_SUCCESS, payload: res.data });
    } catch (err) {
      Sentry.captureException(err);
      dispatch({ type: AUTH_LOADING_FAIL, payload: false });
      toast.error(err?.response?.data.message);
      dispatch({ type: RESET_FAIL, payload: err.response.data });
    }
  };
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  toast.error("Logout Sucessfully");
};

export const controlUserAccess = (data) => (dispatch) => {
  dispatch({ type: CONTROL_USER_ACCESS, payload: data });
};

export const setLoginPreview = (data) => (dispatch) => {
  dispatch({ type: SET_LOGIN_PREVIEW, payload: data });
};

export const resetParties = () => (dispatch) => {
  dispatch({ type: RESET_PARTIES });
};

export const setUserAccess = (data) => (dispatch) => {
  dispatch({ type: SET_USER_ACCESS, payload: data });
};
export const setMessage = (data) => (dispatch) => {
  dispatch({ type: SET_MESSAGE, payload: data });
};

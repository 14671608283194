import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";

const FacebookPrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy policy | Puzzicle</title>
      </Helmet>
      <div className="facebook-privacy-policy">
        <div className="content">
          <h1>Privacy Policy</h1>
          <p>
            This Privacy Policy is prepared by Puzzicle Pte Ltd and whose
            registered address is s at 87 Pasir Panjang Hill, #01-04, Singapore
            118892. (“We”) are committed to protecting and preserving the
            privacy of our visitors when visiting our site or communicating
            electronically with us. This policy sets out how we process any
            personal data we collect from you or that you provide to us through
            our website and social media sites. We confirm that we will keep
            your information secure and comply fully with all applicable
            Singapore Data Protection legislation and regulations. Please read
            the following carefully to understand what happens to personal data
            that you choose to provide to us, or that we collect from you when
            you visit our sites. By submitting information you are accepting and
            consenting to the practices described in this policy.
          </p>
          <h1>Types of information we may collect from you</h1>
          <p>
            We may collect, store and use the following kinds of personal
            information about individuals who visit and use our website and
            social media sites: <br />
            <span>Information you supply to us.</span> You may supply us with
            information about you by filling in forms on our website or social
            media. This includes information you provide when you submit a
            contact/inquiry form. The information you give us may include but is
            not limited to, your name, address, e-mail address, and phone number
          </p>
          <h1>How we may use the information we collect</h1>
          <p>
            We use the information in the following ways: <br />
            <span>Information you supply to us.</span> We will use this
            information:
            <ul>
              <li>
                To provide you with information and/or services that you request
                from us;
              </li>
              <li>To contact you to provide the information requested.</li>
            </ul>
          </p>
          <h1>Disclosure of your information</h1>
          <p>
            Any information you provide to us will either be emailed directly to
            us or may be stored on a secure server. <br />
            We do not rent, sell or share personal information about you with
            other people or non-affiliated companies. <br />
            We will use all reasonable efforts to ensure that your personal data
            is not disclosed to regional/national institutions and authorities
            unless required by law or other regulations. <br />
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of your data
            transmitted to our site; any transmission is at your own risk. Once
            we have received your information, we will use strict procedures and
            security features to try to prevent unauthorized access.
          </p>
          <h1>Your rights – access to your personal data</h1>
          <p>
            You have the right to ensure that your personal data is being
            processed lawfully (“Subject Access Right”). Your subject access
            right can be exercised in accordance with data protection laws and
            regulations. Any subject access request must be made in writing to
            The Director, 87 Pasir Panjang Hill, #01-04, Singapore 118892. We
            will provide your personal data to you within the statutory time
            frames. To enable us to trace any of your personal data that we may
            be holding, we may need to request further information from you. If
            you complain about how we have used your information, you have the
            right to complain to the Information Commissioner’s Office (ICO).
          </p>
          <h1>Changes to our privacy policy</h1>
          <p>
            Any changes we may make to our privacy policy in the future will be
            posted on this page and, where appropriate, notified to you by
            e-mail. Please check back frequently to see any updates or changes
            to our privacy policy.
          </p>
          <h1>Contact</h1>
          <p>
            Questions, comments, and requests regarding this privacy policy are
            welcomed and should be addressed to Carlos Fernandes and The
            Director, 87 Pasir Panjang Hill, #01-04, Singapore 118892.
          </p>
        </div>
      </div>
    </>
  );
};

export default FacebookPrivacyPolicy;

import React from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";

const StripePayment = () => {
  const onToken = (token) => {
    const body = {
      amount: 2200,
      token: token,
    };
    axios
      .post(`${process.env.REACT_APP_BACKEND_ENV}/api/payment`, body)
      .then((response) => {
        alert("Payment Success");
      })
      .catch((error) => {
        alert("Payment Error");
      });
  };

  return (
    <div className="zoom-app-landing">
      <h1>Stripe Checkout</h1>
      <StripeCheckout
        label="Go Premium"
        name="Puzzicle"
        description="Upgrade to a premium account today."
        panelLabel="Get Premium"
        amount={100}
        token={onToken}
        stripeKey={
          "pk_live_51NJUd9CQ5PB503S2vCGS5KyOLLbPY9UttaQUuEZpVLsv5kHVzUn6cJcpYamAVV43fgFeiCLN370KgB1L1DiheWVe00eqWEomTi"
        }
        billingAddress={false}
        style={{ marginTop: 100 }}
      />
    </div>
  );
};

export default StripePayment;

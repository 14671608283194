import {
  STUDY_LOADING_SUCCESS,
  STUDY_LOADING_FAIL,
  CREATE_STUDY_SUCCESS,
  CREATE_STUDY_FAIL,
  GET_SINGLE_STUDY_SUCCESS,
  GET_SINGLE_STUDY_FAIL,
  CLEAR_ERRORS,
  SET_PLAYER_STATE,
} from "../types";

const initState = {
  loading: false,
  message: null,
  party: null,
  playedSeconds: 0.0,
};

const partyReducer = (state = initState, action) => {
  switch (action.type) {
    case STUDY_LOADING_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case STUDY_LOADING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_STUDY_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case GET_SINGLE_STUDY_SUCCESS:
      return {
        ...state,
        party: action.payload.data,
        success: true,
      };
    case GET_SINGLE_STUDY_FAIL:
      return {
        ...state,
        party: action.payload.message,
        success: false,
      };
    case CREATE_STUDY_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
        message: null,
      };
    case SET_PLAYER_STATE:
      return {
        ...state,
        playedSeconds: action.payload,
      };
    default:
      return state;
  }
};

export default partyReducer;

import {
  AUTH_LOADING_SUCCESS,
  AUTH_LOADING_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGET_SUCCESS,
  FORGET_FAIL,
  RESET_SUCCESS,
  RESET_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  CONTROL_USER_ACCESS,
  SET_LOGIN_PREVIEW,
  SET_MESSAGE,
} from "../types";

const initState = {
  error: null,
  message: null,
  loading: false,
  userAccess: true,
  loginPreview: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH_LOADING_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case AUTH_LOADING_FAIL:
      return {
        ...state,
        loading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        message: action.payload.message,
      };
    case FORGET_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case CONTROL_USER_ACCESS:
      return {
        ...state,
        userAccess: action.payload,
      };
    case LOGOUT:
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return {
        ...state,
        error: action.payload,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case CLEAR_ERRORS:
    case FORGET_FAIL:
    case RESET_FAIL:
      return {
        ...state,
        error: null,
      };
    case SET_LOGIN_PREVIEW:
      return {
        ...state,
        loginPreview: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;

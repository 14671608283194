import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./index.scss";

const Documentation = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Support | Puzzicle</title>
      </Helmet>
      <div className="Doc-wraper">
        <div className="content">
          <h1>Puzzicle Documentation</h1>
          <p>
            Welcome to our documentation page! This page contains all the
            information you need to get started with our website or application.
            Please choose from the following options to find the documentation
            you need: Getting Started Our Getting Started guide provides an
            overview of our website or application and walks you through the
            steps to get up and running. This guide is ideal for new users or
            those who are unfamiliar with our platform. User Manual Our User
            Manual provides detailed instructions on how to use our website or
            application. This manual covers all the features and functionality
            of our platform and is designed for users of all levels. API
            Documentation If you're a developer or have programming experience,
            our API documentation provides detailed information on how to use
            our API. This documentation includes code samples, explanations of
            API endpoints, and other technical information. Frequently Asked
            Questions (FAQ) Our FAQ page contains answers to common questions
            about our website or application.
          </p>

          <div className="adding">
            <h1>Adding the App to Zoom</h1>
            <ul>
              <li>Open Zoom App</li>
              <li>Open Apps</li>
              <li>Here we have two tabs go for Discover</li>
              <li>In this we have a Search Box on the left side</li>
              <li>Search Puzzicle app</li>
              <li>Open The Puzzicle app then click on Add button</li>
              <li>
                By clicking Allow, you give permission to this app to use your
                information in accordance with their Terms & Privacy Policy. You
                can remove this app at any time in My Apps.
              </li>
              <li>After this Puzzicle App will be installed</li>
            </ul>
          </div>
          <div className="adding">
            <h1>Usage</h1>
            <ul>
              <li>open puzzicle app from Zoom apps</li>
              <li>then you are prompted a window with text box</li>
              <li>
                In this you need to enter a 6 Digit code that provided by the
                party owner
              </li>
              <li>By clicking Join Party you well join the party</li>
              <li>
                In party you have questions dispalying at defined time. You can
                answer those and participate.
              </li>
            </ul>
          </div>
          <div className="adding">
            <h1>Removing the App</h1>
            <ul>
              <li>
                Login to your Zoom Account and navigate to the Zoom App
                Marketplace.
              </li>
              <li>
                Click Manage &gt;&gt; Added Apps or search for the "Puzzicle"
                app.
              </li>
              <li>Click the "Puzzicle" app</li>
              <li>Click Remove.</li>
            </ul>
          </div>
          <Link to="/" className="ripple">
            Back To Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Documentation;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Lock,
  VisibilityOffRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  resetPassword,
  setLoginPreview,
} from "../../../../redux/_actions/authAction";
import Loader from "../../../loader";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import LogoBox from "../../partails/logoBox";
import "./index.scss";
import { useHistory } from "react-router-dom";
const ResetPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingPreview = useSelector((state) => state.auth.loading);
  const token = props.match.params.token;
  const [loginUser, setloginUser] = useState({
    password: "",
    newPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const { password, newPassword } = loginUser;

  const handleShowPassword = (type) => {
    switch (type) {
      case "password":
        setShowPassword(!showPassword);
        break;
      case "confirm-password":
        setConfirmPassword(!confirmPassword);
        break;

      default:
        break;
    }
  };

  const onChange = (e) => {
    setloginUser({ ...loginUser, [e.target.name]: e.target.value.trim() });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === "" || newPassword === "") {
      toast.error("All fields are required");
    } else if (password.length < 8) {
      toast.error("Password length should be 8 characters");
    } else if (newPassword !== password) {
      toast.error("Password and Confirm Password not matched");
    } else {
      dispatch(resetPassword(token, loginUser.password));
    }
  };

  const redirectHandler = () => {
    history.push("/");
    dispatch(setLoginPreview(true));
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password | Puzzicle</title>
      </Helmet>
      <section className="resetpassword-wrapper">
        <div className="logoBox">
          <LogoBox />
        </div>
        <form className="resetpassword-form" onSubmit={onSubmit}>
          <h3>Reset Password</h3>
          <div className="input-box">
            <Lock />
            <span
              onClick={() => handleShowPassword("password")}
              className="show-password"
            >
              {showPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
            </span>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={onChange}
              placeholder="New Password"
            />
          </div>
          <div className="input-box">
            <Lock />
            <span
              onClick={() => handleShowPassword("confirm-password")}
              className="show-password"
            >
              {confirmPassword ? (
                <VisibilityRounded />
              ) : (
                <VisibilityOffRounded />
              )}
            </span>
            <input
              type={confirmPassword ? "text" : "password"}
              name="newPassword"
              value={newPassword}
              onChange={onChange}
              placeholder="Confirm New Password"
            />
          </div>
          {loadingPreview ? (
            <Button className="disabled">
              <Loader size="small" />
            </Button>
          ) : (
            <>
              <button>Reset Password</button>
              {/* <button onClick={redirectHandler}>Back to login</button> */}
            </>
          )}
        </form>
      </section>
    </>
  );
};
export default ResetPassword;
